.gs-container {
    display: flex;
    flex: 1;
    border-radius: 6px;
    background-color: #e0e0e0;
    padding: 0px 0px;
    flex-direction: row;
    position: relative;
    transform: translateZ(0);
}

.gs-background-slider {
    position: absolute;
    // height: 80%;
    background-color: white;
    border-radius: 6px;
    transition: transform 0.3s ease;
    box-shadow: 0px 0px 2.5px 0px rgba(0,0,0,0.75);
    z-index: 0;
    &:hover {
        background-color: #F3F3F3;
    }
}

.gs-item {
    display: flex;
    flex: 1;
    padding: 4px 8px;
    border-radius: 6px;
    // margin: 0 1px;
    cursor: pointer;
    transition: all 0.2s;
    color: #616161;
    align-items: center;
    justify-content: center;
    position: relative;
    z-index: 1;

    &.gs-padding-compact {
        padding: 2px 4px;
    }

    &.gs-padding-normal {
        padding: 4px 8px;
    }

    &.gs-padding-large {
        padding: 8px 16px;
    }

    &.gs-padding-none {
        padding: 0px;
    }

    &:hover {
        box-shadow: none !important;
        background-color: transparent !important;
    }

    &.selected {
        background-color: transparent !important;
        color: black;
        //Add shadow
        box-shadow: none;

        &:hover {
            background-color: transparent !important;
        }

    }
}

.gs-item-text {
    // flex: 1;
    text-align: center;
    vertical-align: middle;
    // color: black;
    font-size: 16px;
    font-weight: bold;
    padding: 6px;
    margin: 0;
    z-index: 2;

    &.gs-padding-compact {
        padding: 2px 4px;
    }

    &.gs-padding-normal {
        padding: 4px 8px;
    }

    &.gs-padding-large {
        padding: 8px 16px;
    }

    &.gs-padding-none {
        padding: 0px;
    }
}
.gs-item-badge {
    background-color: #c0c0c0;
    border-radius: 50%;
    // margin-left: 8px;
    font-size: 10px;
    font-weight: bold;
    padding: 2px 6px;
    z-index: 2;


}
